import Vue from 'vue'
import { markRaw } from 'vue'

// import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useAppStore } from '@/stores/app'

// Components
//import FooComponent from './components/widgets/FooComponent.vue'

// vue-auth
import axios from 'axios'
import VueAxios from 'vue-axios'

// const vuetify = new Vuetify(...)
import VuetifyConfirm from 'vuetify-confirm'

import VCalendar from 'v-calendar'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()


// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  weekdays: 'W'
})

// Load filters
require('./service/filters')

console.log(JSON.stringify(process.env))

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || ''
Vue.router = router

Vue.use(require('@websanova/vue-auth'), {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  tokenExpired: function () { return false },
  parseUserData: function (data) {
    return data
  },
  refreshData: { url: 'auth/refresh', method: 'GET', enabled: false, interval: 0 }

})

Vue.use(require('vue-moment'))

// import vuelidate from 'vuelidate'

// const validationMixin = vuelidate.validationMixin

// Vue.use(vuelidate.default)

Vue.config.productionTip = false
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Delete',
  buttonFalseText: 'Cancel',
  buttonTrueColor: 'red',
  color: 'red',
  icon: 'warning',
  title: 'Please confirm',
  width: 350,
  property: '$confirm'
})

JSON.safeStringify = (obj, indent = 2) => {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === "object" && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return retVal;
};

const app = new Vue({
  router,
  vuetify,
  pinia,
  render: function (h) { return h(App) }
})

// Patch pinia store init to provide access to $app
pinia.use(({ store }) => {
  store.$app = markRaw(app)
});

// Start app
app.$mount('#app')

<template>
  <div>
    <div v-if="!$auth.ready()">Loading ...</div>
    <v-app v-if="$auth.ready()" id="inspire">

      <v-navigation-drawer v-if="$auth.check()" v-model="drawer" app>
        <router-link to="/">
          <v-img class="mx-3" :src="require('./assets/janus-256.jpg')" height="128px" max-width="100%" dark />
        </router-link>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ account }}</v-list-item-title>
              <v-list-item-subtitle>{{ name }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <template v-for="item in items">
            <v-divider :key="`${item.title}-div`" v-if="item.divider" inset />
            <v-list-item :key="item.title" :to="item.link">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block v-on:click="logout()">
              Logout
              <v-icon right dark>fa-sign-out-alt</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-app-bar app v-if="$auth.check()" color="primary" dark extension-height="0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>
          <zone-path v-if="$route.path.startsWith('/zone/')" :zoneId="$route.params.idOrNew"
            :create="$route.path.endsWith('/new')" mask maskPrimary :maskOffset="68" />
          <div v-else>
            <div :class="appStore.appBarTitleDisabled ? 'janus-model-disabled' : ''">
              <v-icon :disabled="appStore.appBarTitleDisabled">{{ appBarIcon }}</v-icon> {{ appBarTitle }}
            </div>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="showEditButton" icon @click.stop="editClick">
          <v-icon>{{ appStore.editMode ? "mdi-cancel" : "mdi-pencil" }}</v-icon>
        </v-btn>
        <v-progress-linear slot="extension" :indeterminate="true" :active="isLoading" color="white" class="ma-0"
          style="top: -2px;"></v-progress-linear>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>

      <v-footer v-if="$auth.check() && $route.path === '/'" color="primary" app>
        <span class="white--text">
          &copy; 2020-2024 Via Aurelia Solutions -
          <a href="https://www.viaaurelia.fr" class="white--text">www.viaaurelia.fr</a>
        </span>
      </v-footer>

      <v-snackbar v-model="showErrorSnack" color="error">{{ appStore.appServerError }}</v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapStores, mapActions } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useZonesStore } from '@/stores/zones'
import { useUsersStore } from '@/stores/users'


import ZonePath from './components/widgets/ZonePath.vue'
export default {
  components: { ZonePath },
  props: {
    source: String
  },
  data: () => ({
    drawer: false,
    //  account: '',
    //  email: '',
    //  name: '',
    items: [
      { title: 'Users', icon: 'fa-user fa-fw', link: '/users' },
      { title: 'Groups', icon: 'fa-users fa-fw', link: '/usergroups' },
      { title: 'Credentials', icon: 'fa-id-card fa-fw', link: '/credentials' },
      { title: 'Schedules', icon: 'fa-calendar-alt fa-fw' },
      { title: 'Zones', icon: 'fa-building fa-fw', link: '/zones' },
      {
        title: 'Access Templates',
        icon: 'fa-sync fa-fw',
        link: '/accesstemplategroups',
        divider: true
      },
      {
        title: 'Logs',
        icon: 'fa-book fa-fw',
        link: '/logs',
        divider: true
      },
      {
        title: 'Settings',
        icon: 'fa-cogs fa-fw',
        link: '/settings',
        divider: true
      }
    ]
  }),
  computed: {
    ...mapStores(useAppStore),
    showErrorSnack: {
      set: function (v) {
        this.appStore.appServerError = ''
      },
      get: function () {
        return this.appStore.appServerError ? true : false
      }
    },
    account() {
      return this.$auth.check() ? this.$auth.user()?.account?.name :  ''
    },
    email() {
      return this.$auth.check() ? this.$auth.user()?.email : ''
    },
    name() {
      return this.$auth.check() ? this.$auth.user()?.name : ''
    },
    isLoading() {
      return false//return this.$store.state.loadingCount > 0
    },
    showEditButton() {
      if (!this.$route.meta.editButton) return false

      var show = this.$auth.check(this.$route.meta.editPermissions)
      console.log(`App showEditButton for ${this.$route.meta.title} with required permissions ${JSON.stringify(this.$route.meta.editPermissions)}: ${show}`)
      return show
    },
    isAuthenticated() {
      return this.$auth.check()
    },
    appBarIcon() {
      return this.appStore.appBarIcon
    },
    appBarTitle() {
      return this.appStore.appBarTitle || this.$route.meta.title || 'Janus'
    }
  },
  watch: {
    isAuthenticated: function (val) {
      console.log("App isAuthenticated watcher:" + val)
      if (val) {
        this.loadData()
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['toggleEdit']),
    ...mapActions(useZonesStore, ['loadZonesTree']),
    ...mapActions(useUsersStore, ['loadUsers', 'loadUserGroups']),
    editClick() {
      this.toggleEdit()
    },
    refreshUser() {
      /*    AuthDataService.retrieveUser().then(res => {
        this.email = res.data.email
        this.name = res.data.name
        this.account = res.data.account.name
      }) */
    },

    logout() {
      this.$auth.logout({
        makeRequest: true,
        data: {}, // data: {} in axios
        success: function () { },
        error: function () { },
        redirect: '/login'
        // etc...
      })
    },
    loadData() {
      console.log("Loading App data")
      this.refreshUser()
      this.loadZonesTree()
      this.loadUsers()
      this.loadUserGroups()
    }
  },
  mounted() {
    console.log("Mounting App with $auth.check()=" + this.$auth.check())
    if (this.$auth.check()) {
      this.loadData()
    }
  }
}
</script>

<style>
.janus-model-disabled {
  font-style: oblique;
  color: grey;
}

.v-toolbar__extension {
  padding: 0px !important;
}

.v-data-table__mobile-row {
  min-height: 32px !important;
}
</style>

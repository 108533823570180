import Vue from 'vue'
import VueRouter from 'vue-router'
import { useAppStore } from '../stores/app'

Vue.use(VueRouter)

const makeCamelCase = str => str.split(' ').map((e,i) => i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()).join('')
const makePermissionName = str => makeCamelCase(str).replace(/s$/, '')

function idPropCast (route) {
  const props = { ...route.params }
  if (typeof (props.id) !== 'undefined') { props.id = +props.id ? +props.id : props.id }
  return props
}

function idOrNewPropCast (route) {
  const props = { ...route.params }
  if (typeof (props.idOrNew) !== 'undefined') {
    if (props.idOrNew === 'new') {
      return { create: true }
    } else {
      return { id: +props.idOrNew ? +props.idOrNew : props.idOrNew } // Handle Int with cast to Number of UUID
    }
  }
  return props
}

// eslint-disable-next-line
function genericPage (title, withId = false, withEdit = false, editPerms = {}) {
  const componentName = title.replace(/\s+/g, '')
  const pathName = componentName.toLowerCase()
  return {
    path: `/${pathName}${withId ? '/:idOrNew' : ''}`,
    name: componentName,
    component: () => import(`../components/pages/${componentName}`),
    meta: {
      auth: true,
      title: title,
      editButton: withEdit === true,
      editPermissions: editPerms?.keys?.length ? editPerms : { [makePermissionName(title)]: 'write' }
    },
    props (route) {
      return idOrNewPropCast(route)
    }
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/pages/Login'),
    meta: {
      auth: false,
      title: 'Login'
    }
  },
  {
    path: '/password/resetlink',
    name: 'PasswordResetLink',
    component: () => import('../components/pages/PasswordResetLink'),
    meta: {
      auth: false,
      title: 'Password reset request'
    }
  },
  {
    path: '/password/reset/:token',
    name: 'PasswordReset',
    component: () => import('../components/pages/PasswordReset'),
    props: true,
    meta: {
      auth: false,
      title: 'Password reset'
    }
  },
  {
    path: '/register/:token',
    name: 'Register',
    component: () => import('../components/pages/PasswordReset'),
    props (route) {
      const props = { ...route.params }
      props.register = true
      return props
    },
    meta: {
      auth: false,
      title: 'Register'
    }
  },
  {
    // Code in query string
    path: '/auth/bs/response',
    name: 'BSAuthResponse',
    component: () => import('../components/pages/BSAuthResponse'),
    meta: {
      auth: true,
      title: 'Booking Sync Authorization'
    }
  },
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../components/pages/Welcome'),
    meta: {
      auth: true,
      title: 'Welcome'
    }
  },
  {
      path: '/users',
      name: 'Users',
      component:  () => import('../components/pages/Users'),
      meta: {
        auth: true,
        title: 'Users',
        editButton: true,
        editPermissions: { user: 'write' }
      },
      props: route => ({
        routeQuery: route.query
      })
  },
  genericPage('User', true, true),
  genericPage('User Groups', false, true),
  genericPage('User Group', true, true),
  genericPage('Zones', false, true),
  {
    // Important to define this before the regular zone route
    // else it could be matched when no parent id is defined.
    path: '/zone/:id?/new',
    name: 'New Zone',
    component: () => import('../components/pages/Zone'),
    props (route) {
      var props = idPropCast(route)
      return {
        create: true,
        parentId: props.id
      }
    },
    meta: {
      auth: true,
      title: 'New zone',
    }
  },
  genericPage('Zone', true, true),
  genericPage('Simple User', true),
  genericPage('Credentials'),
  genericPage('Access Template Groups'),
  genericPage('Access Template Group', true),
  genericPage('Rentals'),
  genericPage('Rental', true),
  genericPage('Settings'),
  genericPage('Logs'),
  genericPage('Dev'),
  {
    path: '/*',
    name: '404',
    component: () => import('../components/pages/404')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
    const appStore = useAppStore()
    appStore.appBarTitle = to.meta?.title
    appStore.appBarIcon = null
    appStore.appBarTitleDisabled = false
    appStore.updateTitle()
})


export default router

import { JanusDataService, janus } from './janus'
// Load filters
require('./filters')

export class NotificationProfileDataService extends JanusDataService {
  constructor () {
    super('notification-profiles')
  }

  enable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/enable`, request, callback)
  }

  disable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/disable`, request, callback)
  }
}

import { janus, JanusDataService } from './janus'

export class CredentialDataService extends JanusDataService {
  constructor () {
    super('credentials')
  }

  retrieveAllForUser (userId, request, callback) {
    return janus.request('get', `/credentials/user/${userId}`, request, callback)
  }
}

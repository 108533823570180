import axios from 'axios'
import { useAppStore } from '@/stores/app'

const janus = {
  // pSuccessMessage: Message string to be set to successMessage in case of success
  // pDebounceTime: Debounce time in millis (0 if no debounce)
  request (method, url, req, callback) {
    req.loading = true
    req.pending = true
    req.complete = false
    req.success = false
    req.errorMessage = ''
    req.successMessage = ''

    // Start debounce
    req._debounceElapsed = false
    if (req.pDebounceTime) {
      req.debounce = true
      req._timeout = setTimeout(() => {
        console.log('Debounce elapsed')
        if (!req.loading) {
          req.debounce = false
        }
        req._debounceElapsed = true
      }, req.pDebounceTime)
    }

    const axiosReq = {
      method: method,
      url: url,
      data: req.data
    }

    console.log(`AXIOS request: ${JSON.stringify(axiosReq)}`)
    axios(axiosReq).then(
      res => {
        req.complete = true
        req.success = true
        if (req._debounceElapsed) req.debounce = false
        req.processComplete = false
        req.loading = false
        req.successMessage = req.pSuccessMessage
        req.errorMessage = ''
        req.response = res.data
        if (callback) callback(req)
        req.pending = false
      },
      res => {
        console.log(`Error on ${method} request for ${url}: ${res.response?.status}  - ${JSON.stringify(res.response?.data)}`)
        req.complete = true
        req.success = false
        if (req._debounceElapsed) req.debounce = false
        req.processComplete = false
        req.loading = false
        req.successMessage = ''
        req.errorMessage = `${req.pErrorMessage} Status:${res.response?.status} Details:${JSON.stringify(res.response?.data)}`
        const appStore = useAppStore()
        appStore.appServerError = req.errorMessage
        req.response = res.data
        if (callback) callback(req)
        req.pending = false
      }
    )
  },

  formatMessage () {
    'use strict'
    var str = this.toString()
    if (arguments.length) {
      var t = typeof arguments[0]
      var key
      var args = (t === 'string' || t === 'number')
        ? Array.prototype.slice.call(arguments)
        : arguments[0]

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key])
      }
    }

    return str
  }
}

export class JanusRequest {
  constructor (errorMsg = '', successMsg = '') {
    this.pErrorMessage = errorMsg
    this.pSuccessMessage = successMsg
    this.loading = false
    this.success = false
    this.complete = false
    this.errorMessage = ''
    this.successMessage = ''
    this.debounce = false
    this.processComplete = true
    this.pending = false
  }

  reset () {
    this.loading = false
    this.success = false
    this.complete = false
    this.errorMessage = ''
    this.successMessage = ''
    this.debounce = false
    this.processComplete = true
    this.pending = false
  }

  isFailed () {
    return this.complete && !this.success
  }
}

/** Registry of all data services */
var janusDS = {}

export class JanusDataService {
  constructor (endpoint) {
    this.path = `/${endpoint}`

    console.log('Registering JanusDS for endpoint:' + endpoint)
    janusDS[endpoint] = this
  }

  /**
   * Sets a wrapper function to be called before the actual callback.
   * This can be used for example to refresh a data store after a modification endpoint is called.
   * By default, it is called on all modification methods: create, update, upsert and delete.
   *
   * @param {function} wrapper - The wrapper function to be called before the actual callback.
   */
  set callbackWrapper (wrapper) {
    this.ccbWrap = wrapper
  }

  /**
   * Wraps a request callback so that the `callbackWrapper` of the
   * data service is called before the callback itself.
   *
   * @param {function} callback - The actual callback function to be executed after the wrapper.
   * @returns {function} - A wrapped callback function.
   */
  wrapCallBack (callback) {
    return (req) => {
      if (this.ccbWrap) {
        this.ccbWrap(req)
      }
      if (callback) callback(req)
    }
  }

  retrieveAll (request, callback, withDeleted) {
    return janus.request('get', this.path + (withDeleted ? '?withDeleted' : ''), request, callback)
  }

  /** Fetches one page from the API
   * @param page page number
   * @param per number of items per page
   * @param options object to be passed in the query (filtering options..)
   */
  retrievePage (request, page, per, options, callback) {
    var query = `${this.path}/page?page=${page}&per=${per}`
    if (options) query = query + `&${JanusDataService.serializeQuery(options)}`
    janus.request('get', query, request, callback)
  }

  /** Fetches the first item from the API that matches the query
   * @param options object to be passed in the query (filtering options..)
   */
  retrieveFirst (request, options, callback) {
    var query = `${this.path}/first`
    if (options) query = query + `?${JanusDataService.serializeQuery(options)}`
    janus.request('get', query, request, callback)
  }

  retrieve (id, request, callback) {
    return janus.request('get', `${this.path}/${id}`, request, callback)
  }

  update (id, request, callback) {
    return janus.request('put', `${this.path}/${id}`, request, this.wrapCallBack (callback))
  }

  create (request, callback) {
    return janus.request('post', this.path, request, this.wrapCallBack (callback))
  }

  upsert (id, request, callback) {
    if (id) {
      return this.update(id, request, this.wrapCallBack (callback))
    } else {
      return this.create(request, this.wrapCallBack (callback))
    }
  }

  delete (id, request, callback) {
    return janus.request('delete', `${this.path}/${id}`, request, this.wrapCallBack (callback))
  }

  static serviceForEndpoint (endpoint) {
    const service = janusDS[endpoint]
    if (!service) {
      console.warn(`No data service for endpoint ${endpoint} in ${Object.keys(janusDS)}`)
    }
    return service
  }

  /// Serializes an object ot be used in URL query strings
  static serializeQuery (obj) {
    var str = []
    for (var p in obj) {
      if (typeof (obj[p]) !== 'undefined' && obj[p] != null) {
        if (typeof(obj[p]) == 'object') {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(JSON.stringify(obj[p])))
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      }
    }
    return str.join('&')
  }
}

export { janus }

import { janus, JanusDataService } from './janus'

export class ARProvisioningDataService extends JanusDataService {
  constructor () {
    super('oarps')
  }

  retrieveAll(request, callback) {
    return janus.request('get', '/oarps', request, callback)
  }

  retrieveAllForDoor (doorId, request, callback) {
    return janus.request('get', `/oarps/door/${doorId}`, request, callback)
  }

  retrieveAllForUser (userId, request, callback) {
    return janus.request('get', `/oarps/user/${userId}`, request, callback)
  }

  retrieveAllForDoorAndUser (doorId, userId, request, callback) {
    return janus.request('get', `/oarps/door/${doorId}/user/${userId}`, request, callback)
  }
}

export class ProvisioningStatus {
  static get active() { return 1 << 0 }

  /// Provisioning state
  static get provisioning() { return 1 << 1 }

  /// Status used when the data needs to be provisioned in the future
  static get pending() { return 1 << 2 }

  /// Status used when the data is neither provisioned, nor provisioning (due to error or unsupported data
  static get blocked() { return 1 << 3 }

  /// Status used when some data has been stored in the target
  static get stored() { return 1 << 5 }


  /// Provisioning state details
  /// Provisioning state set for inital data
  static get provInit() { return 1 << 16 }
  /// Provisioning state set for data that needs to be updated
  static get provUpdate() { return 1 << 17 }
  /// Provisioning state set for data that is beign stored
  static get provStoring() { return 1 << 18 }
  /// Provisioning state set for data that is being deleted
  static get provDeleting() { return 1 << 19 }
  /// Provisioning state set for data that is being deleted
  static get provBlocked() { return 1 << 20 }

  /// Error status
  static get error() { return 1 << 24 }

  /// Provisioning data is not supported by target device
  static get notSupported() { return 1 << 25 }

  /// Status created on site (device
  static get createdOnSite() { return 1 << 31 }

  constructor (rawValue) {
    this.rawValue = rawValue
  }

  isProvisioning () {
    return rawValue & ProvisioningStatus.provisioning
  }

  isPending () {
    return this.rawValue & ProvisioningStatus.pending
  }

  isBlocked () {
    return this.rawValue & ProvisioningStatus.blocked
  }

  isStored () {
    return this.rawValue & ProvisioningStatus.stored
  }

  }

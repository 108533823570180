import { JanusDataService, janus } from './janus'
// Load filters
require('./filters')

export class BSAccountDataService extends JanusDataService {
  constructor () {
    super('bs')
  }

  enable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/enable`, request, callback)
  }

  disable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/disable`, request, callback)
  }
}

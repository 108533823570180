import { JanusDataService, janus } from './janus'

export class AccessTemplateGroupDataService extends JanusDataService {
  constructor () {
    super('bs-access-template-groups')
  }

  updateAccessRights (id, request, callback) {
    janus.request('post', `${this.path}/${id}/update-access-rights`, request, callback)
  }
}

import { JanusDataService, janus } from './janus'
// Load filters
require('./filters')

export class BookingDataService extends JanusDataService {
  constructor () {
    super('bs-bookings')
  }

  freeze (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/freeze`, request, callback)
  }

  unfreeze (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/unfreeze`, request, callback)
  }
}

import { janus } from './janus'
import { JanusDataService } from './JanusDataService'

export class LoggedEventsDataService {
  getAccessEvents (request, page, per, filter, callback) {
    janus.request('get', `access-events?page=${page}&per=${per}&${JanusDataService.serializeQuery(filter)}`, request, callback)
  }

  getDoorEvents (request, page, per, filter, callback) {
    janus.request('get', `door-events?page=${page}&per=${per}&${JanusDataService.serializeQuery(filter)}`, request, callback)
  }
}

import { janus, JanusDataService } from './janus'
import { useZonesStore } from '@/stores/zones'

export class ZoneDataService extends JanusDataService {
  constructor () {
    super('zones')
    super.callbackWrapper = ( req ) => { this.zoneTreeRefreshingCallback(req) }
    this.zonesStore = null
  }

  get store() {
    if (!this.zonesStore) {
      this.zonesStore = useZonesStore()
    }

    return this.zonesStore
  }

  /** Fetch zones and their children in a tree structure.
   *
   * @param {*} id If null, get all root zones
   * @param {*} request
   * @param {*} callback
   */
  retrieveTree (request, callback) {
      return janus.request('get', `${this.path}/tree`, request, callback)
  }

  retrieveMatrix (request, callback) {
    return janus.request('get', `${this.path}/matrix`, request, callback)
  }

  retrieveMatrixForZone (id, request, callback) {
    return janus.request('get', `${this.path}/matrix/${id}`, request, callback)
  }

  /**
     * Retrieve outer zones of a specific zone
     * @param {*} id
     * @param {*} request
     * @param {*} callback
     */
  retrieveOuter (id, request, callback) {
    return janus.request('get', `${this.path}/${id}/outer`, request, callback)
  }

  addOuter (id, outerId, request, callback) {
    return janus.request('post', `${this.path}/${id}/outer/${outerId}`, request, this.wrapCallBack(callback))
  }

  removeOuter (id, outerId, request, callback) {
    return janus.request('delete', `${this.path}/${id}/outer/${outerId}`, request, this.wrapCallBack(callback))
  }

  retrieveChildren (id, request, callback) {
    return janus.request('get', `${this.path}/${id}/children`, request, callback)
  }

  move (id, parentId, request, callback) {
    if (parentId) {
      return janus.request('put', `${this.path}/${id}/parent/${parentId}`, request, this.wrapCallBack(callback))
    } else {
      return janus.request('delete', `${this.path}/${id}/parent`, request, this.wrapCallBack(callback))
    }
  }

  zoneTreeRefreshingCallback (request) {
    if (request.success) this.store.loadZonesTree()
  }

  /** Recursively processes zones API response to allow proper display in vue-treeselect
   * @return An object containing 'tree' of zones where no leaf node have a 'children' key, and 'list' of zones, where each zone is indexed by its id.
  */
  static normalizedZones (response) {
    var list = {}
    var doorList = {}
    response.forEach(zone => {
      this.normalizeZone(zone, null, list, doorList)
      this.countInSubZones(zone)
    })

    for (const zone of Object.values(list)) {
      zone.granted = zone.grantedIds.map(grantedId => {
        return list[grantedId]
      })
    }

    return {
      tree: response,
      list: list,
      doors: doorList
    }
  }

  /** Sorts zones according to their labelPath */
  static sort (z1, z2) {
    let zp1 = z1.labelPath
    let zp2 = z2.labelPath
    if (!(zp1 && zp2)) return 0 // Do not sort if error loading labels
    for (let i = 0; i < Math.min(zp1.length, zp2.length); i++) {
      if (!(zp1[i] === zp2[i])) return zp1[i].localeCompare(zp2[i])
    }
    return zp1.length - zp2.length // If prefix matches, the greatest is the one with more components
  }

  static normalizeZone (zone, parent, list, doorList) {
    list[zone.id] = zone
    zone.doors.forEach(door => {
      doorList[door.id] = door
    })
    zone.parent = parent
    zone.path = parent ? parent.path.concat(parent) : []
    zone.labelPath = parent ? parent.labelPath.concat([zone.name]) : [zone.name]
    zone.label = zone.name
    if (zone.children.length === 0) {
      delete zone.children // treeselect needs no array if no children
    } else {
      zone.children.forEach(child => this.normalizeZone(child, zone, list, doorList))
    }
  }

  static countInSubZones (zone) {
    var startCount = {
      children: zone.children ? zone.children.length : 0,
      doors: zone.doors ? zone.doors.length : 0
    }

    if (zone.children?.length) {
      zone.count = zone.children.reduce((accum, child) => {
        const count = this.countInSubZones(child)
        accum.children += count.children
        accum.doors += count.doors
        return accum
      }, startCount)
    } else {
      console.log
      zone.count = startCount
    }

    return zone.count
  }
}

import { JanusDataService, janus } from './janus'
// Load filters
require('./filters')

export class UserDataService extends JanusDataService {
  constructor () {
    super('users')
  }

  /** Recursively processes Users API response to allow proper display in vue-treeselect
   * @return An object containing 'tree' of users with a 'Deleted' section at the end gathering all deleted users, and 'list' of users, where each user is indexed by its id.
  */
  static normalizedUsers (response) {
    var list = {}
    var active = []
    var deleted = []
    response.forEach(user => this.normalizeUser(user, null, list))
    response.sort((u1, u2) => u1.label.localeCompare(u2.label))
    response.forEach(user => {
      if (user.deleted) {
        deleted.push(user)
      } else {
        active.push(user)
      }
    })

    active.push({
      id: 'Deleted',
      label: 'Deleted',
      children: deleted
    })

    return {
      tree: active,
      list: list
    }
  }

  static normalizeUser (user, parent, list) {
    list[user.id] = user
    user.label = this.username(user)
  }

  static username (user) {
    if (user.firstName && user.lastName) { return `${user.lastName}, ${user.firstName}` } else { return `${user.lastName}${user.firstName}` }
  }
}

import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    editMode: false,
    appServerError: '',
    appBarTitle: '',
    appBarTitleDisabled: false,
    appBarIcon: ''
  }),
  getters: {
    // doubleCount: (state) => state.count * 2,
  },
  actions: {
    toggleEdit() {
      this.editMode = !this.editMode
    },
    updateTitle(title = null, icon = null, disabled = false) {
      this.appBarTitle  = title
      this.appBarIcon = icon
      this.appBarTitleDisabled = disabled
      document.title = ['Janus', this.$app.$route.meta?.title, title].filter(Boolean).join(' - ');
    },
  },
})

import { defineStore } from 'pinia'
import { JanusRequest, UserDataService, UserGroupDataService } from '@/service/janus'

const userDS = new UserDataService()
const userGroupDS = new UserGroupDataService()

export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      userTree: [],
      userList: {},
      userLoading: false,

      // Groups
      userGroups: [],
      userGroupLoading: false,
    }
  },
  getters: {
    getUserById: (state) => {
      return (userId) => state.userList[userId]
    },
    getUserGroups: (state) => {
      return Object.values(state.userGroups)
    }
  },
  actions: {
    loadUsers() {
      if (!this.userLoading) {
        console.log('Loading Users tree inside pinia store')
        var req = new JanusRequest()
        userDS.retrieveAll(req, () => {
          console.log('Processing Users received into store')
          if (req.success) {
            let processed = UserDataService.normalizedUsers(req.response)
            this.userTree = processed.tree
            this.userList = processed.list
          } else {
            console.error('Error while processing Users received into store')
            this.userTree = []
            this.userList = {}
          }
          this.userLoading = false
        })
      } else {
        console.log('Skipping Users load in pinia already in progress')
      }
    },
    loadUserGroups() {
      if (!this.userGroupLoading) {
        console.log('Loading User Groups inside pinia store')
        var req = new JanusRequest()
        userGroupDS.retrieveAll(req, () => {
          console.log('Processing User Groups received into store')
          if (req.success) {
            this.userGroups = req.response
          } else {
            console.error('Error while processing User Groups received into store')
            this.userGroups = []
          }
          this.userGroupLoading = false
        })
      } else {
        console.log('Skipping User Groups load in pinia already in progress')
      }
    }
  }
})

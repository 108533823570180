import { JanusDataService, janus } from './janus'

export class RentalDataService extends JanusDataService {
  constructor () {
    super('bs-rentals')
  }

  retrieveAllForAccessTemplateGroup (id, request, callback) {
    return janus.request('get', `${this.path}/access-template-group/${id}`, request, callback)
  }

  linkAccessTemplates (id, request, callback) {
    janus.request('post', `${this.path}/${id}/access-link/`, request, callback)
  }

  unlinkAccessTemplates (id, request, callback) {
    janus.request('post', `${this.path}/${id}/access-unlink/`, request, callback)
  }
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#293a06',
        secondary: '#ff0000',
        accent: '#68950d',
        error: '#b71c1c'
      }
    }
  }
});

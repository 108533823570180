import { janus, JanusDataService } from './janus'

export class AccessRightDataService extends JanusDataService {
  constructor () {
    super('accessrights')
  }

  retrieveAllForZone (zoneId, request, callback) {
    return janus.request('get', `/accessrights/zone/${zoneId}`, request, callback)
  }

  retrieveAllForUser (userId, request, callback) {
    return janus.request('get', `/accessrights/user/${userId}`, request, callback)
  }

  retrieveAllForUserGroup (userGroupId, request, callback) {
    return janus.request('get', `/accessrights/user-group/${userGroupId}`, request, callback)
  }
}

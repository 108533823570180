import { JanusDataService } from './janus'

export class AccessTemplateDataService extends JanusDataService {
  constructor() {
    super('bs-access-templates')
  }

  static pad(n, width, z) {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
  }

  /** Converts App extend object from web page structure to API.
  * May return null as a valid API object, undefined if input invalid.
  */
  static extend2Api(appObject) {
    if (appObject.type === 'at') {
      // No extension
      return {
        ref: appObject.ref
      }
    } else if (appObject.type === 'fixed') {
      // Fixed time of day
      var parts = appObject.time.match(/^(\d{2}):(\d{2}):(\d{2})$/)
      return {
        ref: appObject.ref,
        extend: {
          type: 'timeOfDay',
          value: {
            hour: parseInt(parts[1]),
            minute: parseInt(parts[2])
          }
        }
      }
    } else if (appObject.type === 'time') {
      // Time before after
      return {
        ref: appObject.ref,
        extend: {
          type: appObject.when === 'before' ? 'timeBefore' : 'timeAfter',
          value: {
            hour: parseInt(appObject.hours),
            minute: parseInt(appObject.minutes)
          }
        }
      }
    } else {
      // undefined return
    }
  }

  static api2Extend(api) {
    if (typeof (api.extend.type) === 'undefined' || api.extend.type === null) {
      // No extension
      return {
        type: 'at',
        ref: api.ref,
        time: null,
        when: null,
        hours: null,
        minutes: null
      }
    } else if (api.extend.type === 'timeOfDay') {
      // Fixed time of day
      return {
        type: 'fixed',
        ref: api.ref,
        time: `${this.pad(api.extend.value.hour, 2)}:${this.pad(api.extend.value.minute, 2)}:00`,
        when: null,
        hours: null,
        minutes: null
      }
    } else if (api.extend.type === 'timeBefore' || api.extend.type === 'timeAfter') {
      // Time before/after
      return {
        type: 'time',
        ref: api.ref,
        when: api.extend.type === 'timeBefore' ? 'before' : 'after',
        hours: api.extend.value.hour,
        minutes: api.extend.value.minute,
        time: null // Necessary for correct reactive behaviour in case of change
      }
    } else {
      // undefined return
    }
  }
}

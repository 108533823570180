import { janus, JanusDataService } from './janus'

export class UserGroupDataService extends JanusDataService {
  constructor() {
    super('user-groups')
  }

  // Add any specific methods for UserGroupsDataService if needed
  addMember(groupId, memberId, request, callback) {
    return janus.request('post', `${this.path}/${groupId}/members/${memberId}`, request, this.wrapCallBack(callback))
  }

  removeMember(groupId, memberId, request, callback) {
    return janus.request('delete', `${this.path}/${groupId}/members/${memberId}`, request, this.wrapCallBack(callback))
  }
}

export { janus }

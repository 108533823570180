<template>
    <path-navigator :path="zonePath" v-on:nav-to="navToZone" :mask="mask" :maskOffset="maskOffset" :maskPrimary="maskPrimary" />
</template>

<script>
import { mapState } from 'pinia'
import { useZonesStore } from '@/stores/zones'

import PathNavigator from './PathNavigator'

export default {
  name: 'ZonePath',
  components: {
    PathNavigator
  },
  props: {
    zoneId: {},
    create: {type: Boolean, default: false},
    mask: {type: Boolean, default: false},
    maskOffset: {type: Number, default: 0},
    maskPrimary: {type: Boolean, default: false}
  },
  computed: {
    zonePath () {
      const zone = this.list[this.zoneId]

      if (typeof (zone) === 'undefined') return []
      console.log(`Refreshing zonepath for zoneId:${this.zoneId} create:${this.create}`)

      var path = this.flatten(zone)

      if (this.create) {
        path.push({
          name: 'New zone',
          inEdition: true
        })
      }

      return path
    },
    ...mapState(useZonesStore, ['tree', 'list']),
  },
  methods: {
    navToZone: function (toId) {
      // TODO: check for unsaved data
      this.$router.push(`/zone/${toId}`)
    },
    flatten (zone) {
      // const flat = [];

      if (zone.parent != null) {
        const parentPath = this.flatten(zone.parent)
        parentPath.push({
          id: zone.id,
          name: zone.name
        })
        return parentPath
      } else {
        return [
          {
            id: zone.id,
            name: zone.name || '...'
          }
        ]
      }
    }
  }
}
</script>


import { defineStore } from 'pinia'
import { JanusRequest, ZoneDataService, ARProvisioningDataService } from '@/service/janus'

const zds = new ZoneDataService()
const ards = new ARProvisioningDataService()

export const useZonesStore = defineStore('zones', {
  state: () => {
    return {
      tree: [],
      list: {},
      doors: {},
      loading: false,

      // ARProvisioning data
      arProvOverall: null,
      arProvZones: {}, // Object keyed by zone IDs
      arProvDoors: {}, // Object keyed by door IDs
      arProvLoading: false
    }
  },
  getters: {
    getZoneById: (state) => {
      return (zoneId) => state.list[zoneId]
    },
    // ARProvisioning getters
    getZoneARProvData: (state) => {
      return (zoneId) => state.arProvZones[zoneId] || null
    },
    getDoorARProvData: (state) => {
      return (doorId) => state.arProvDoors[doorId] || null
    }
  },
  actions: {
    loadZonesTree() {
      if (!this.loading) {
        console.log('Loading Zones tree inside pinia store')
        var req = new JanusRequest()
        zds.retrieveTree(req, () => {
          console.log('Processing Zones received into store')
          if (req.success) {
            let processed = ZoneDataService.normalizedZones(req.response)
            this.tree = processed.tree
            this.list = processed.list
            this.doors = processed.doors
          } else {
            console.error('Error while processing Zones received into store')
            this.tree = []
            this.list = {}
            this.doors = {}
          }
          this.loading = false
        })
      } else {
        console.log('Skipping Zones load in pinia already in progress')
      }
    },

    loadARProvisioningData() {
      if (!this.arProvLoading) {
        console.log('Loading ARProvisioning data inside pinia store')
        this.arProvLoading = true
        var req = new JanusRequest()
        ards.retrieveAll(req, () => {
          console.log('Processing ARProvisioning data received into store')
          if (req.success) {
            this.arProvOverall = req.response.overall
            this.arProvZones = req.response.byZone
            this.arProvDoors = req.response.byDoor
            console.log(`Loaded ARProvisioning data: ${Object.keys(this.arProvZones).length} zones, ${Object.keys(this.arProvDoors).length} doors`)
          } else {
            console.error('Error while processing ARProvisioning data received into store')
            this.arProvOverall = null
            this.arProvZones = {}
            this.arProvDoors = {}
          }
          this.arProvLoading = false
        })
      } else {
        console.log('Skipping ARProvisioning data load in pinia already in progress')
      }
    }
  }
})

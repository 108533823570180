<template>
  <div :class="`${mask ? 'container' : ''}`">
    <ul class="breadcrumb">
      <div v-if="mask" class="breadcrumb-mask"
        :style="{
          left: `${maskOffset}px`,
          'background-image': `linear-gradient(to left, rgba(255,255,255,0), ${maskPrimary ? 'var(--v-primary-base)' : 'white'})`
        }"
      ></div>
      <li v-for="(item, index) in path" :key="item.id">
        <i v-if="index != 0" class="pn--chevron px-1 mdi mdi-chevron-right"></i>
        <button class="btn"
          v-on:click="$emit('nav-to', item.id)"
          :class = '[{"pn--edit": item.inEdition}]'
          :disabled="index == path.length-1"
          >
          {{ item.name }}</button>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'PathNavigator',
  props: ['path', 'mask', 'maskOffset', 'maskPrimary']
}
</script>

<style scoped>
.pn--level {
  text-align: left;
  display: inline;
}
.pn--edit {
  font-style:italic;
}
.container {
  display: inline-flex;      /* Make container as wide as content on larger screens */
  justify-content: flex-end; /* Right align inner ul so overflow is pushed off the left side */
  overflow: hidden;
  max-width: 100%;            /* not sure what limits your width but you need to set a max-width */
}

.breadcrumb {
  white-space: nowrap;       /* make sure crumbs are on one line */
  margin: 0;
  padding: 0;
}

.breadcrumb>li {
  display: inline-block;      /* need to be inline or inline-block for nowrap to work */
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-mask {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 20px;
  z-index: 2;
  pointer-events: none;
}

</style>

import { janus } from './janus'

export class AuthDataService {
  passwordResetLink (request) {
    janus.request('post', '/auth/password/resetlink/', request)
  }

  passwordReset (request) {
    janus.request('post', '/auth/password/reset/', request)
  }

  invitationLink (request) {
    janus.request('post', '/auth/invitation/link', request)
  }

  invitationResend (userId, request, callback) {
    janus.request('post', `/auth/invitation/${userId}`, request, callback)
  }

  invitationRegister (request) {
    janus.request('post', '/auth/invitation/register', request)
  }

  bsCodeUpdate (code, request) {
    janus.request('post', `/bs/authorization-code?code=${code}`, request)
  }

  bsAuthURI (request, callback) {
    janus.request('get', 'bs/authorization', request, callback)
  }
}

import Vue from 'vue'

var moment = require('moment')

Vue.filter('yesNo', value => {
  return typeof (value) === 'undefined' ? '-'
    : (value ? ' Yes' : 'No')
})

Vue.filter('username', value => {
  if (!value) return 'undefined'
  let comps = [value.lastName, value.firstName]
  if (value.lastName || value.firstName) {
    return comps.join(', ')
  } else {
    return '-'
  }
})

Vue.filter('userLevel', value => {
  if (value == 'admin') {
    return 'Administrator'
  } else if (value == 'manager') {
    return 'Manager'
  } else if (value == 'reader') {
    return 'Reader'
  } else return 'Custom'
})

Vue.filter('userStatus', value => {
  switch (value) {
    case 'invitationSent':
      return 'Invitation Sent'
    case 'invitationExpired':
      return 'Invitation Expired'
    case 'registered':
      return 'Registered'
    case 'passwordReset':
      return 'Waiting for password reset'
    case 'loggedIn':
      return 'Last Seen'
    case 'suspended':
      return 'Suspended'
    case 'deleted':
      return 'Deleted'
    default:
      return 'Unknown'
  }
})

Vue.filter('credentialType', value => {
  switch (value) {
    case 'pin':
      return 'PIN'
    case 'mifareDesfire':
      return 'RFID'
    default:
      return 'Unknown'
  }
})

Vue.filter('arProvStatus', value => {
  switch (value) {
    case 1 << 6:
      return 'Deleted'
    case 65539:
      return 'Provisioning'
    case 1 << 19:
      return 'Deleting…'
    case 33:
      return 'Stored'
    default:
      return `Unknown (${value})`
  }
})
Vue.filter('oarpDGColor', value => {
  switch (value.alert) {
    case 'info':
      switch (value.state) {
        case 'ok':
          return 'green'
        case 'planned':
          return 'cyan'
        case 'disabled':
          return 'grey'
        default:
          return 'primary'
      }
    case 'caution':
      return 'orange'
    case 'warning':
      return 'red'
  }
})

Vue.filter('oarpIcon', value => {
  switch (value.label) {
    case 'stored':
      return 'mdi-check-circle'
    case 'planned':
      return 'mdi-calendar-start'
    case 'provisioning':
      switch (value.state) {
        case 'planned':
          return 'mdi-calendar-start'
        case 'inProgressDevice':
          return 'mdi-download-lock'
        case 'inProgressNetwork':
          return 'mdi-cloud-download'
        default:
          return 'mdi-sync'
      }
  }
})
Vue.filter('oarpDGText', value => {
  return value.label
})
Vue.filter('zoneIcon', value => {
  let name = value.name
  if (value.zoneId) { return 'mdi-door' }
  if (!value.parentId) { return 'fa-building' }
  if (!name) { return 'mdi-map-marker' }
  if (/\d$/.test(name)) {
    return 'mdi-bed'
  } else if (name.endsWith('ascenseur')) {
    return 'mdi-elevator-passenger'
  } else if (name.endsWith('technique') || name.endsWith('lectrique')) {
    return 'mdi-lightning-bolt'
  } else if (name.endsWith('ommunes') || /escalier/i.test(name)) {
    return 'mdi-stairs'
  } else if (name.toLowerCase() == 'sous-sol') {
    return 'mdi-stairs-down'
  } else if (name.endsWith('Stock') || /Service/i.test(name) ) {
    return 'mdi-paper-roll'
  }  else if (/linge/i.test(name)) {
    return 'mdi-menu'
  }  else if (name == "Hall d'entrée") {
    return 'mdi-office-building'
  } else if (name == 'Bagagerie') {
    return 'mdi-bag-suitcase'
  } else {
    if (value.children) {
      return 'mdi-folder'
    } else {
      return 'mdi-map-marker'
    }
  }
})
Vue.filter('datetime', value => {
  return moment(value).format('YYYY-MM-DD, HH:mm')
})

Vue.filter('datetimesec', value => {
  return moment(value).format('YYYY-MM-DD, HH:mm:ss')
})

Vue.filter('timeHumanAndSec', value => {
  if (!value) return '-'

  const date = moment(value, true)
  if (date) {
    return `${date.fromNow()} (${date.format('YYYY-MM-DD, HH:mm:ss')})`
  } else {
    return '-'
  }
})

Vue.filter('timeHuman', value => {
  if (!value) return '-'

  const date = moment(value, true)
  if (date) {
    return date.fromNow()
  } else {
    return '-'
  }
})

Vue.filter('timeHumanRel', (value, from) => {
  if (!value) return '-'

  const date = moment(value, true)
  const fromDate = moment(from, true)
  if (date && fromDate) {
    let str = date.from(fromDate, true)
    let isAfter = date.diff(fromDate) > 0
    return `${str} ${ isAfter ? 'after' : 'before'}`
  } else {
    return '-'
  }
})

Vue.filter('scheduleShort', value => {
  if (value == null || typeof (value.schedule) === 'undefined' || value.schedule == null) {
    return 'Always'
  } else if (value.schedule.type === 'startEnd') {
    const start = moment(value.schedule.startDate)
    const end = moment(value.schedule.endDate)
    if (start.isSame(end, 'day')) {
      return `${start.format('YYYY-MM-DD, HH:mm')} to ${end.format('HH:mm')}`
    } else {
      return `${start.format('YYYY-MM-DD, HH:mm')} to ${end.format('YYYY-MM-DD, HH:mm')}`
    }
  } else {
    return `<${value.schedule.type} : ${value.id}>`
  }
})

Vue.filter('scheduleHuman', value => {
  if (value == null || typeof (value.schedule) === 'undefined' || value.schedule == null) {
    return 'Always'
  } else if (value.schedule.type === 'startEnd') {
    const start = moment(value.schedule.startDate)
    const end = moment(value.schedule.endDate)
    var text = ''
    if (end.isBefore()) {
      text = 'Ended ' + end.fromNow() + ' '
    } else if (start.isAfter()) {
      text = 'Starting ' + start.fromNow() + ' '
    } else {
      text = 'Active, ending ' + end.fromNow() + ' '
    }
    if (start.isSame(end, 'day')) {
      return text + `(${start.format('YYYY-MM-DD, HH:mm')} to ${end.format('HH:mm')})`
    } else {
      return text + `(${start.format('YYYY-MM-DD, HH:mm')} to ${end.format('YYYY-MM-DD, HH:mm')})`
    }
  } else {
    return `<${value.schedule.type} : ${value.id}>`
  }
})

function scheduleClass(value) {
  if (value == null || typeof (value.schedule) === 'undefined' || value.schedule == null) {
    return 'schedule-active'
  } else if (value.schedule.type === 'startEnd') {
    const start = moment(value.schedule.startDate)
    const end = moment(value.schedule.endDate)
    if (start && end) {
      if (moment().isBefore(start)) {
        return 'schedule-future'
      } else if (moment().isAfter(end)) {
        return 'schedule-passed'
      } else {
        return 'schedule-active'
      }
    } else {
      return 'schedule-error'
    }
  } else {
    return 'schedule-error'
  }
}

Vue.filter('scheduleClass', value => {
  return scheduleClass(value)
})

Vue.filter('scheduleColor', value => {
  switch (scheduleClass(value)) {
    case 'schedule-active':
      return 'green'
    case 'schedule-passed':
      return 'lightgray'
    case 'schedule-future':
      return 'cyan'
    default:
      return 'orange'
  }
})

Vue.filter('eventColor', value => {
  return value?.granted ? 'green' : 'red'
})

Vue.filter('bookingStatus', value => {
  if (typeof (value) === 'undefined' || value == null) {
    return null
  } else if (value.options & 2) {
    return {
      text: 'Frozen',
      color: 'orange'
    }
  } else {
    return {
      text: 'Active',
      color: 'green'
    }
  }
})


Vue.filter('lockSubtitle', value => {
  var tokens = []
  switch (value.lock?.type) {
    case 'aperio':
      tokens.push(`HW: ${value.lock.address}`)
      break
    case '2n':
      tokens.push(`IP: ${value.lock.address}`)
      break
  }
  if (value.comment?.length) tokens.push(value.comment)
  return tokens.join(' • ')
})

Vue.filter('zonePath', value => {
  return value?.labelPath ? value.labelPath?.join('<span class="mdi mdi-chevron-right"></span>')
    : (value?.id ? value.id : '-')
})

Vue.filter('zonePathText', value => {
  return value?.labelPath ? value.labelPath?.join(' > ')
    : (value?.id ? value.id : '-')
})

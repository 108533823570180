import { janus, JanusDataService } from './janus'
import { useZonesStore } from '@/stores/zones'

export class DoorDataService extends JanusDataService {
  constructor () {
    super('doors')
    super.callbackWrapper = ( req ) => { this.zoneTreeRefreshingCallback(req) }
    this.zonesStore = null
  }

  get store() {
    if (!this.zonesStore) {
      this.zonesStore = useZonesStore()
    }

    return this.zonesStore
  }

  zoneTreeRefreshingCallback (request) {
    if (request.success) this.store.loadZonesTree()
  }

  retrieveAllForZone (doorId, request, callback) {
    return janus.request('get', `/credentials/zones/${zoneId}/doors`, request, callback)
  }

  enable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/enable-offline`, request, callback)
  }

  disable (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/disable-offline`, request, callback)
  }

  refreshOfflineFeedbacks (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/refresh-offline-fb`, request, callback)
  }

  refreshOfflineRights (id, request, callback) {
    return janus.request('post', `${this.path}/${id}/refresh-offline-rights`, request, callback)
  }
}

import { JanusDataService, janus } from './janus'

export class SimpleUserDataService extends JanusDataService {
  constructor () {
    super('users/simple')
  }

  delete (id, request, callback) {
    return janus.request('delete', `/users/${id}`, request, callback)
  }

  retrieve (id, request, callback) {
    return janus.request('get', `/users/${id}`, request, callback)
  }
}
